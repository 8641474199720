<template>
  <div style="width: 100%; margin-top: 10px">
    <div class="card" style="border: none">
      <div class="card-body">
        <table class="scroll-container">
          <tr>
            <td
              class="list-group-item"
              style="height: 50px; margin-bottom: 0px; margin-top: 0px"
            >
              <h5><i class="bi bi-person-plus"></i>매일매일 LIGHT UP</h5>
            </td>
          </tr>
          <tr>
            <td
              class="list-group-item"
              style="height: 100px; margin-bottom: 0px; margin-top: 0px"
            >
              <div class="row">
                <div
                  class="col-2"
                  style="
                    font-size: 14px;
                    margin-top: 8px;
                    padding-left: 0px;
                    padding-right: 0px;
                  "
                >
                  날짜:
                </div>
                <div class="col-6">
                  <select
                    class="form-control"
                    id="todate"
                    name="todate"
                    v-model="todate"
                    @change="GetDataALL()"
                  >
                    <option
                      v-for="(option, index) in dateList"
                      :key="index"
                      :value="option.opendate"
                      :selected="todate === option.opendate"
                    >
                      {{ option.opendate }}
                    </option>
                  </select>
                </div>
                <div class="col-4">
                  <button
                    type="button"
                    @click="kakaoLinkShare"
                    class="btn btn-warning btn-sm"
                  >
                    카카오공유
                  </button>
                </div>
              </div>
              <div class="row">
                <div
                  class="col-2"
                  style="
                    font-size: 14px;
                    margin-top: 8px;
                    padding-left: 0px;
                    padding-right: 0px;
                  "
                >
                  성경:
                </div>
                <div class="col-6">
                  <select
                    class="form-control"
                    id="bible"
                    name="bible"
                    v-model="bible"
                    @change="GetDataALL()"
                  >
                    <option :value="1" :selected="bible === '1'">
                      쉬운성경
                    </option>
                    <option :value="2" :selected="bible === '2'">
                      개역개정
                    </option>
                  </select>
                </div>
                <div class="col-4"></div>
              </div>
            </td>
          </tr>
          <tr>
            <td
              style="
                padding-top: 0px;
                padding-bottom: 0px;
                padding-right: 0px;
                padding-left: 0px;
              "
            >
              <div
                style="text-align: left; letter-spacing: 1.5px"
                v-html="replaceNewlinesWithBreaks(dataList)"
              ></div>
            </td>
          </tr>
          <tr>
            <td
              style="
                text-align: left;
                font-size: 16px;
                color: brown;
                margin-top: 20px;
              "
            >
              <p class="text-left">
                지금 하나님이 나에게 하시는 말씀은 무엇인가요?
                <input
                  class="form-check-input"
                  type="checkbox"
                  value=""
                  id="defaultCheck1"
                  v-model="shared"
                />
                <label class="form-check-label" for="defaultCheck1">
                  공유
                </label>
              </p>
              <div class="row">
                <div class="col-10">
                  <textarea
                    class="form-control"
                    id="exampleFormControlTextarea1"
                    rows="3"
                    v-model="meditation"
                  ></textarea>
                </div>
                <div class="col-2">
                  <p>
                    <button
                      type="button"
                      @click="meditationModify"
                      class="btn btn-success btn-sm"
                      v-if="isButtonDisabled"
                    >
                      수정
                    </button>
                  </p>
                </div>
              </div>
            </td>
          </tr>
          <tr v-if="readableday" style="margin-top: 20px">
            <td>
              <button
                type="button"
                class="btn btn-success"
                @click="IReadBible()"
                :disabled="isButtonDisabled"
                v-if="!isButtonDisabled"
              >
                말씀을 읽었습니다.
              </button>
              <button
                type="button"
                class="btn btn-secondary"
                :disabled="isButtonDisabled"
                v-else-if="userid === null"
              >
                로그인을 해주세요.
              </button>
              <button
                type="button"
                class="btn btn-secondary"
                :disabled="isButtonDisabled"
                v-else
              >
                오늘 말씀을 모두 읽었습니다.
              </button>
            </td>
          </tr>
          <tr v-else style="margin-top: 20px">
            <td>
              <button type="button" class="btn btn-secondary" disabled>
                오늘이나 이전일만 가능합니다.
              </button>
            </td>
          </tr>
        </table>
      </div>
    </div>
    <div v-if="playonoff" style="font-size: 15px; color: blue">
      <i class="fa fa-headphones" aria-hidden="true"></i>
      오늘은 드라마 바이블로 들어볼까?
      <p style="font-size: 10px; color: black">
        (데이터 다운로드에 따라 재생 시 끊김이 있을 수 있습니다.)
      </p>
    </div>
    <audio :src="playlist" controls v-if="playonoff"></audio>

    <div style="font-size: 15px; margin-top: 10px">
      <i class="fa fa-microphone" aria-hidden="false"></i> 오늘은 내가 녹음해
      볼게요~
      <p style="font-size: 10px">
        (녹음 시 중단없이, 화면 꺼짐 X, 재녹음은 가능)
      </p>
    </div>
    <div style="padding-bottom: 20px">
      <button
        type="button"
        @click="startRecording"
        class="btn btn-danger btn-sm"
        :disabled="isRecording"
      >
        <i class="fa fa-microphone" aria-hidden="false"></i> 녹음 시작
      </button>

      <button
        type="button"
        @click="stopRecording"
        class="btn btn-primary btn-sm"
        :disabled="!isRecording"
        style="margin-left: 14px"
      >
        <i class="fa fa-microphone" aria-hidden="false"></i> 녹음 중지
      </button>
      <!-- <div style="width: 10px" v-if="audioUrl">녹음된 자료입니다.</div>
      <audio :src="audioUrl" controls v-if="audioUrl"></audio>  -->
      <div style="width: 10px"></div>
    </div>
  </div>
</template>

<script>
  import apiService from "../services/apiService";
  import { globalVariable } from "../store/store";
  //import { watch } from 'vue';

  //import axios from 'axios';

  //import $ from "jquery";
  export default {
    props: {
      slideNumber: Number,
    },
    data() {
      return {
        dataList: [],
        todate: this.GettoDay(),
        dateList: {},
        //userinfo : localStorage.getItem('user') ? localStorage.getItem('user') : '0',
        userid:
          localStorage.getItem("user") == null
            ? null
            : JSON.parse(localStorage.getItem("user")).id,
        isButtonDisabled: false,
        dailyname: "",
        feed: 0,
        readableday: true,
        meditation: "",
        shared: true,
        eventData: "",
        globalVar: globalVariable,
        bible: localStorage.getItem("myBible")
          ? localStorage.getItem("myBible")
          : "1",
        isRecording: false,
        mediaRecorder: null,
        audioChunks: [],
        audioUrl: null,
        playlist: null,
        playonoff: false,
      };
    },
    //   mounted() {
    //   // 이벤트 수신
    //   this.$eventBus.onEvent((data) => {
    //     this.eventData = data;
    //     console.log(" 이벤트 수신 = >",data );
    //   });
    //  },
    watch: {
      slideNumber(newValue) {
        if (newValue === 3) {
          this.userid =
            localStorage.getItem("user") == null
              ? ""
              : JSON.parse(localStorage.getItem("user")).id;
          if (this.userid == null || this.userid == "") {
            this.$toast.show("로그인이 필요합니다.", {
              type: "warning",
              position: "top-right",
            });
          }
          console.log("슬라이드 번호 3  말씀 = ", newValue);
          this.GetDayALL();
          this.GetDataALL();
        }
      },
      feed: "onFeedChange",
    },
    created() {
      this.playlist = null;
      this.GetDayALL();
      this.GetDataALL();
      this.ifBibleReadYn();
      this.getBibleVoiceRecoding();
    },
    methods: {
      GetDayALL() {
        if (globalVariable.value !== 0) {
          this.todate = globalVariable.value;
        }
        const data = {
          today: this.todate,
        };

        console.log("globalVar =", globalVariable.value + "  " + data.today);
        apiService
          .getBibleReadDay(data)
          .then((response) => {
            //console.log("GetDayALL list = ", response);
            this.dateList = response.data;
            //  this.SiteLists = response.data;
          })
          .catch((e) => {
            console.log(e);
          });
      },
      GetDataALL() {
        localStorage.setItem("myBible", this.bible);
        this.playlist = null;

        if (this.todate > this.GettoDay()) {
          this.readableday = false;
        } else {
          this.readableday = true;
        }
        const idata = {
          todate: this.todate,
          bible: this.bible,
        };

        console.log("idata ==> ", idata);

        apiService
          .getDataALLBible(idata)
          .then((response) => {
            // console.log("getDataALLBible list = ", response);
            this.dataList = response.data[0].sentence;
            this.dailyname = response.data[0].dailyname;
            this.ifBibleReadYn();
            this.getBibleVoiceRecoding();
          })
          .catch((e) => {
            console.log(e);
          })
          .finally(() => {
            // 데이터 가져오기 완료
            this.isLoading = false;
          });
      },
      IReadBible() {
        //오늘 말씀을 다 읽었습니다.

        //공유인 경우 1, 아닌 경우 0
        if (this.shared) this.shared = 1;
        else this.shared = 0;

        const data = {
          today: this.todate,
          userid: this.userid,
          meditation: this.meditation,
          shared: this.shared,
        };

        if (
          data.userid == null ||
          data.userid == "undefined" ||
          data.userid == ""
        ) {
          this.$toast.show("먼저 로그인이 필요합니다.", {
            type: "warning",
            position: "top-right",
          });
          return;
        }

        if (
          this.todate == null ||
          this.todate == "undefined" ||
          this.todate == ""
        ) {
          this.$toast.show("날짜를 확인해주세요.", {
            type: "warning",
            position: "top-right",
          });
          return;
        }

        apiService
          .ireadbibletoday(data)
          .then((response) => {
            console.log("ireadbibletoday >> ", response.data);
            this.feed = response.data;
            this.isButtonDisabled = true;

            this.$toast.show("말씀온도가 올라갔습니다." + this.feed, {
              type: "success",
              position: "top-right",
            });
          })
          .catch((e) => {
            console.log(e);
          });
        console.log("feed >> ", this.feed);
      },
      onFeedChange(newFeed, oldFeed) {
        // 변경된 값을 사용하여 원하는 동작을 수행합니다.
        // console.log('feed 변수가 변경되었습니다.');
        // console.log('새로운 값:', newFeed);
        // console.log('이전 값:', oldFeed);
        // if (newFeed > 0) {
        //   this.$toast.show("말씀온도가 " + newFeed + "도 올라갔습니다.", {
        //     type: "success",
        //     position: "top-right",
        //   });
        //   this.feed = oldFeed;
        // }
        console.log(newFeed, oldFeed);
        // 예를 들어 다른 동작을 수행하거나 이벤트를 트리거할 수 있습니다.
      },
      ifBibleReadYn() {
        //오늘 말씀을 읽었는지 체크함

        this.meditation = "";
        this.isButtonDisabled = false;

        const data = {
          today: this.todate,
          userid: this.userid,
        };
        apiService
          .ifbiblereadyn(data)
          .then((response) => {
            if (response.data && response.data[0]) {
              // API 응답이 존재하고 response.data[0]도 존재할 때만 접근
              this.meditation = response.data[0].meditation;
              this.shared = response.data[0].shared;

              if (response.data[0].shared === "1") {
                this.shared = true;
              } else {
                this.shared = false;
              }

              if (response.data[0].mb_id && response.data[0].mb_id.length > 0) {
                this.isButtonDisabled = true;
              } else {
                this.isButtonDisabled = false;
              }
            } else {
              // API 응답이 없거나 response.data[0]가 없는 경우에 대한 처리
              console.log("묵상글이 없습니다.");
            }

            //console.log("ifbiblereadyn list = ", response.data[0]);

            // this.meditation = response.data[0].meditation;
            // this.shared = response.data[0].shared;

            // if(response.data[0].shared == "1" ){
            //   this.shared = true;
            // } else {
            //   this.shared = false;
            // }

            // if(response.data[0].mb_id.length > 0 ){
            //   this.isButtonDisabled = true;
            // } else {
            //   this.isButtonDisabled = false;
            // }
          })
          .catch((e) => {
            console.log(e);
          });
      },

      //개행문자 인식
      replaceNewlinesWithBreaks(str) {
        if (typeof str !== "string") {
          return str; // 문자열이 아닌 경우 변환 없이 반환
        }
        return str.replace(/\n/g, "<br>");
      },
      GettoDay() {
        // 현재 날짜를 가져오기
        const today = new Date();

        // 년, 월, 일을 각각 가져오기
        const year = today.getFullYear();
        const month = String(today.getMonth() + 1).padStart(2, "0"); // 월은 0부터 시작하므로 +1 해준 후 두 자리로 포맷팅
        const day = String(today.getDate()).padStart(2, "0"); // 일자를 두 자리로 포맷팅

        // YYYYMMDD 형식으로 조합
        const formattedDate = `${year}${month}${day}`;

        //console.log("formattedDate=", formattedDate); // 예: ""
        return formattedDate;
      },

      kakaoLinkShare() {
        //카카오 링크 공유하기
        // 카카오톡 링크 생성
        window.Kakao.Link.sendDefault({
          objectType: "text",
          text: "오늘 읽을 말씀은 " + this.dailyname + "입니다.", // 공유할 메시지 내용
          link: {
            mobileWebUrl: "https://bible.dongan1718.com/", // 모바일 웹에서 열릴 링크
          },
          buttonTitle: "앱에서 보기", // 카카오톡 내부에서 보일 버튼 텍스트
          buttons: [
            {
              title: "웹에서 보기", // 카카오톡 외부에서 보일 버튼 텍스트
              link: {
                webUrl: "https://bible.dongan1718.com/", // 웹에서 열릴 링크
              },
            },
          ],
        });
      },

      // 묵상 수정
      meditationModify() {
        //공유인 경우 1, 아닌 경우 0
        if (this.shared) this.shared = 1;
        else this.shared = 0;

        const data = {
          today: this.todate,
          userid: this.userid,
          meditation: this.meditation,
          shared: this.shared,
        };

        //console.log("this.data", data);

        apiService
          .meditationModify(data)
          .then((response) => {
            console.log("meditationModify >> ", response.data[0]);
            //this.feed = response.data[0].feed;
            this.isButtonDisabled = true;

            this.$toast.show("수정되었습니다.", {
              type: "success",
              position: "top-right",
            });
          })
          .catch((e) => {
            console.log(e);
          });
        console.log("feed >> ", this.feed);
      },
      /////////////////  보이스 레코딩 영역 ///////////////////////
      async startRecording() {
        const confirmRecording = window.confirm(
          "녹음을 시작하시겠습니까? 기존 녹음은 삭제됩니다."
        );
        if (!confirmRecording) {
          return;
        }

        if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
          alert("Your browser does not support audio recording");
          return;
        }

        try {
          const stream = await navigator.mediaDevices.getUserMedia({
            audio: true,
          });
          this.mediaRecorder = new MediaRecorder(stream);
          this.audioChunks = [];

          this.mediaRecorder.ondataavailable = (event) => {
            this.audioChunks.push(event.data);
          };

          this.mediaRecorder.onstop = this.handleStop;

          this.mediaRecorder.start();
          this.isRecording = true;
        } catch (error) {
          console.error("Error accessing the microphone", error);
        }
      },
      stopRecording() {
        if (this.mediaRecorder) {
          this.mediaRecorder.stop();
          this.isRecording = false;
        }
      },
      async handleStop() {
        const audioBlob = new Blob(this.audioChunks, { type: "audio/wav" });
        this.audioUrl = URL.createObjectURL(audioBlob);

        const filename = this.generateDateWithRandomNumber() + ".wav";
        const formData = new FormData();
        formData.append("audio", audioBlob, filename);
        formData.append("userid", this.userid);
        formData.append("todate", this.todate);

        try {
          apiService
            .voicerecoding(formData)
            .then((response) => {
              console.log("voicerecoding >> ", response.data);
              //this.feed = response.data[0].feed;
              this.isButtonDisabled = true;

              this.$toast.show(
                "녹음이 저장되었습니다. 화면 새로고침을 하세요.",
                {
                  type: "success",
                  position: "top-right",
                }
              );

              console.log("Audio file successfully uploaded", response.data);
            })
            .catch((e) => {
              console.log(e);
            });
        } catch (error) {
          console.error("Error uploading audio file", error);
        }
      },

      generateDateWithRandomNumber() {
        const now = new Date();
        const year = now.getFullYear();
        const month = String(now.getMonth() + 1).padStart(2, "0"); // 월은 0부터 시작하므로 +1
        const day = String(now.getDate()).padStart(2, "0");
        const randomNum = String(Math.floor(1000 + Math.random() * 9000)); // 1000에서 9999 사이의 4자리 숫자

        return `${year}${month}${day}-${randomNum}`;
      },

      getBibleVoiceRecoding() {
        const idata = {
          todate: this.todate,
        };

        apiService
          .getvoicerecoding(idata)
          .then((response) => {
            console.log("getvoicerecoding list = ", response.data[0]);

            if (response.data[0].length > 1) {
              this.playonoff = true;
            } else {
              this.playonoff = false;
            }

            this.playlist =
              "https://bible.dongan1718.com/recoding/" + response.data[0];
          })
          .catch((e) => {
            console.log(e);
          })
          .finally(() => {
            // 데이터 가져오기 완료
          });
      },
    },

    beforeMount() {
      this.GettoDay();
    },
    setup() {},
  };
</script>

<style scoped>
  .scroll-container {
    overflow-x: hidden; /* 가로 스크롤 숨기기 */
    max-height: 90vh;
    width: 100%; /* 테이블의 너비를 100%로 설정 */
    border-collapse: collapse; /* 테이블 경계선이 겹치도록 설정 */
  }

  .scroll-container tr {
    border-bottom: 1px solid #ddd; /* 각 행의 하단에 경계선 추가 */
  }

  .scroll-container td {
    padding: 8px; /* 셀 내에 패딩 추가 */
  }

  .list-group-item {
    border: none; /* 리스트 그룹 아이템의 경계선 제거 */
  }
</style>
