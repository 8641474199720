<template>
  <div class="container-fluid mt-5">
    <h1 class="text-center">말씀온도</h1>
    <div class="row">
      <div class="col-md-3 offset-md-3">
        <canvas
          id="barChart"
          class="chart"
          :style="{ height: chartHeight }"
        ></canvas>
      </div>
    </div>
  </div>
</template>

<script>
  import { Chart, registerables } from "chart.js";
  //import axios from "axios";
  // 전체 진행율, 평균진행율, 나의 진행율
  Chart.register(...registerables);
  import apiService from "../services/apiService";

  export default {
    props: {
      slideNumber: Number,
    },
    data() {
      return {
        chartData: {
          labels: ["말씀 온도"],
          datasets: [
            {
              label: "",
              data: [], // 빈 배열로 초기화
              backgroundColor: ["#ff0000"],
            },
          ],
        },
        chartHeight: "70vh", // 모바일에서 높이를 90vh로 설정
        userinfo:
          localStorage.getItem("user") !== null
            ? localStorage.getItem("user")
            : "",
        chartInstance: null, // 차트 인스턴스 변수 추가
      };
    },
    watch: {
      slideNumber(newValue) {
        if (newValue === 8) {
          //console.log(" slideNumber ?? ", newValue);
          console.log("슬라이드 번호 9 말씀온도= ", newValue);
          this.getDrawData();
        }
      },
    },
    created() {
      this.getDrawData();
      if (localStorage.getItem("user") === null) {
        // swiper.slideToClickedSlide(1);
      }
    },
    mounted() {},
    methods: {
      getDrawData() {
        const idata = {
          todate: "20240101",
        };

        apiService
          .drawGraphData(idata)
          .then((response) => {
            this.totalsum = response.data[0].totsum;
            //console.log("this.totalsum",this.totalsum);
            this.generateRandomDataAndCreateChart();
            //  this.SiteLists = response.data;
          })
          .catch((e) => {
            console.log(e);
          });
      },
      generateRandomDataAndCreateChart() {
        // 데이터를 차트 데이터에 할당
        this.chartData.datasets[0].data = [this.totalsum];
        this.chartData.datasets[0].label = "현재온도:" + this.totalsum;

        // 차트 생성
        this.createChart();
      },
      createChart() {
        if (this.chartInstance) {
          this.chartInstance.destroy(); // 이전 차트 파괴
        }
        const ctx = document.getElementById("barChart").getContext("2d");

        this.chartInstance = new Chart(ctx, {
          type: "bar",
          responsive: true,
          data: this.chartData,
          options: {
            scales: {
              y: {
                display: true,
                beginAtZero: true, // y 축의 눈금을 0부터 시작
                max: 20000, // y 축의 최대 값 설정
                //stepSize: 500, // 눈금 간격 설정
                ticks: {
                  stepSize: 5000, // Interval between ticks
                  callback: function (value, index) {
                    // value는 각 눈금 값입니다.
                    // index는 눈금의 인덱스입니다.
                    // values는 모든 눈금 값의 배열입니다.
                    //console.log("values",values);

                    // 여기서는 간단한 예로 'a', 'b', 'c'와 같은 글자를 반환합니다.
                    // 실제로는 눈금 값에 따라 다른 글자를 반환하도록 로직을 작성하면 됩니다.
                    //const labels = ['a', 'b', 'c', 'd', 'e']; // 눈금 값에 대응하는 글자 배열
                    //const labels = ["0", "600",         "1000", "1500", "2000", "2500",   "3000", "3500", "4000",   "4500", "5000",  "5500", "6000",  "6500", "7000",  "7500", "8000",  "8500", "9000",  "9500", "10000", "10500", "11000", "11500","12000"];
                    //const labels2 = ["0", "음료(젤리)", "회식비", "쿠키", "회식비", "과자", "회식비", "간식", "회식비", "젤리", "회식비", "선물", "회식비", "선물", "회식비", "선물", "회식비", "선물", "회식비", "선물", "회식비,선물", "", "회식비", "","회식비"];

                    const labels = ["0", "5000", "10000", "20000"];
                    //const labels2 = ["", "", "", ""];

                    if (index < labels.length) {
                      //return labels[index]+" "+labels2[index]; // 눈금 값에 대응하는 글자 반환
                      return labels[index]; // 눈금 값에 대응하는 글자 반환
                    } else {
                      return ""; // 더 이상 글자가 없으면 빈 문자열 반환
                    }
                  },
                },
              },
            },
            legend: {
              display: false, // 범주를 표시하지 않음
            },
          },
        });
      },
    },
  };
</script>

<style scoped>
  /* 모바일에서 차트를 화면에 맞게 렌더링 */
  .chart {
    max-width: 100%; /* 부모 요소에 맞게 차트 크기 조절 */
    height: 100%; /* 높이를 부모 요소의 100%로 설정 */
  }
</style>
