<template>
  <div class="image-container">
    <div class="scroll-container">
      <!-- 여기에 슬라이드 또는 컨텐츠를 배치합니다. -->
      <div class="card" style="border: none">
        <div class="card-header" style="font-weight: bold">
          나의 묵상 게시판
        </div>
        <div>
          <!-- <div class="row">
              <div class="col-2" style="font-size: 14px;margin-top: 12px; padding-left: 20px; padding-right: 0px;">날짜:</div>
              <div class="col-6">
                <select class="form-control" id="todate6" name="todate6" v-model="todate6" @change="GetReadDonePerson()" style="    margin-top: 5px;">
                  <option v-for="(option, index) in dateList" :key="index" :value="option.opendate" :selected="todate6 === option.opendate">{{ option.opendate }}</option>
                </select>        
              </div>   
            </div> -->
        </div>
        <div style="height: 10px"></div>
        <div
          class="card-body"
          style="
            text-align: left;
            padding-top: 5px;
            padding-right: 5px;
            padding-left: 5px;
            padding-bottom: 5px;
            font-size: 12px;
          "
        >
          <table class="table table-striped">
            <thead>
              <tr>
                <th scope="col"></th>
                <th scope="col"></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, idx) in items" :key="idx">
                <th scope="row">{{ idx + 1 }}</th>
                <td>
                  <div style="color: blue; font-weight: 600">
                    {{ item.rdate }}
                    <span v-show="item.shared === '1'" style="color: #ffc107"
                      >(공유)</span
                    >
                  </div>
                  <div>{{ item.meditation }}</div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <!-- paging -->

        <p></p>
        <div>
          <nav aria-label="Page navigation example">
            <ul class="pagination justify-content-center">
              <li class="page-item disabled">
                <a
                  class="page-link"
                  v-if="page > 1"
                  @click="previousPage"
                  aria-label="Previous"
                  ><span aria-hidden="true">&laquo;</span></a
                >
              </li>
              <li
                class="page-item"
                v-for="pageNumber in pageNumbers"
                :key="pageNumber"
                @click="changePage(pageNumber)"
                :class="{ active: pageNumber === page }"
              >
                <a class="page-link">{{ pageNumber }}</a>
              </li>
              <li class="page-item">
                <a
                  class="page-link"
                  v-if="page < totalPages"
                  @click="nextPage"
                  aria-label="Next"
                  ><span aria-hidden="true">&raquo;</span></a
                >
              </li>
            </ul>
          </nav>
        </div>

        <!-- paging -->
      </div>
    </div>
  </div>
</template>

<script>
  import apiService from "../services/apiService";
  export default {
    props: {
      slideNumber: Number,
    },
    data() {
      return {
        buttonClasses: [
          "btn-primary",
          "btn-secondary",
          "btn-success",
          "btn-danger",
        ],
        todate6: this.GettoDay(),
        dateList: {},
        userid:
          localStorage.getItem("user") == null
            ? ""
            : JSON.parse(localStorage.getItem("user")).id,
        personList: {},
        feed2: 0,
        personListCount: 0,
        currentPage: 1,
        itemsPerPage: 5,
        totalItems: this.personListCount,
        page: 1,
        limit: 12,
        items: [],
      };
    },
    watch: {
      slideNumber(newValue) {
        if (newValue === 7) {
          //console.log(" slideNumber ?? ", newValue);
          this.userid =
            localStorage.getItem("user") == null
              ? ""
              : JSON.parse(localStorage.getItem("user")).id;
          if (this.userid == null || this.userid == "") {
            this.$toast.show("로그인이 필요합니다.", {
              type: "warning",
              position: "top-right",
            });
          }
          console.log("슬라이드 번호 7 나의 묵상 = ", newValue);
          this.GetReadDonePerson();
        }
      },
      feed2: "onRecommadChg",
    },
    computed: {
      totalPages() {
        return Math.ceil(this.totalItems / this.limit);
      },
      pageNumbers() {
        const numbers = [];
        for (let i = 1; i <= this.totalPages; i++) {
          numbers.push(i);
        }
        return numbers;
      },
    },
    methods: {
      setPage(pageNumber) {
        this.currentPage = pageNumber;
        this.GetReadDonePerson(); // 페이지 변경 시 데이터 새로고침
      },
      getRandomClass() {
        const randomIndex = Math.floor(
          Math.random() * this.buttonClasses.length
        );
        return "btn " + this.buttonClasses[randomIndex] + " check";
      },
      GetDayALL() {
        const data = {
          today: this.todate6,
        };
        apiService
          .getBibleReadDay(data)
          .then((response) => {
            this.dateList = response.data;
            //  this.SiteLists = response.data;
          })
          .catch((e) => {
            console.log(e);
          });
      },
      GettoDay() {
        // 현재 날짜를 가져오기
        const today = new Date();

        // 년, 월, 일을 각각 가져오기
        const year = today.getFullYear();
        const month = String(today.getMonth() + 1).padStart(2, "0"); // 월은 0부터 시작하므로 +1 해준 후 두 자리로 포맷팅
        const day = String(today.getDate()).padStart(2, "0"); // 일자를 두 자리로 포맷팅

        // YYYYMMDD 형식으로 조합
        const formattedDate = `${year}${month}${day}`;

        //console.log("formattedDate >> ", formattedDate); // 예: ""
        return formattedDate;
      },
      GetReadDonePerson() {
        const data = {
          userid: this.userid,
          todate: this.todate6,
          page: this.page,
          limit: this.limit,
        };
        apiService
          .getBibleMyDiary(data)
          .then((response) => {
            //console.log("response.data", response.data);

            this.totalItems = response.data[0][0];
            this.items = response.data.slice(1, 30);
            //this.personList = response.data.slice(1,30);

            //this.items = response.data.slice(1,30);
            //this.personListCount =  this.personList.length == null ? 0 : this.personList.length;
            //  this.SiteLists = response.data;
          })
          .catch((e) => {
            console.log(e);
          });
      },
      changePage(page) {
        this.page = page;
        this.GetReadDonePerson();
      },
      previousPage() {
        if (this.page > 1) {
          this.page--;
          this.GetReadDonePerson();
        }
      },
      nextPage() {
        if (this.page < this.totalPages) {
          this.page++;
          this.GetReadDonePerson();
        }
      },
    },
    mounted() {
      this.GetDayALL();
      this.GetReadDonePerson();
    },
  };
</script>

<style scoped>
  .image-container {
    display: flow;
    width: 90%;
    /* display: flow; */
    margin-top: 20px;
    margin-left: 10px;
    margin-right: 10px;
    align-items: center;
    min-height: 100vh;
    background-color: #ffffff; /* 이미지 아래 여백을 채우기 위한 배경색 */
  }

  .scroll-container {
    max-width: 90vw; /* 반응형으로 가로 크기 설정 */
    max-height: 90vh; /*반응형으로 세로 크기 설정 */
    overflow-y: auto; /* 세로 스크롤을 활성화합니다. */
    overflow-x: hidden; /* Hide horizontal scrollbar */
  }

  .check {
    margin-left: 10px;
    margin-bottom: 5px;
  }

  .recombtn {
    margin-left: 10px;
  }
</style>
