import http from "../http-common";

class ManageService {

  async getDataALLBible(data) {  // 말씀읽어오기
    return await http.post(`/api/bible_read.php`, data);
  }
  async getBibleReadDay(data) {  // 말씀읽어오기
    return await http.post(`/api/day_bible.php`, data);
  }
  ireadbibletoday(data) {  // 말씀 다 읽음
    return http.post(`/api/bible_done.php`, data);
  }
  async getBibleReadMonth(data) {  // 달력뿌리기용 함수
    return await http.post(`/api/bible_readmonth.php`, data);
  }

  async jonMember(data) {  // 로그인하다.
    return await http.post(`/api/jonMember.php`, data);
  }

  async forgetPwd(data) {  // 비밀번호를 초기화 하다
    return await http.post(`/api/forgetPwd.php`, data);
  }

  async login(data) {  // 로그인하기
    return await http.post(`/api/login.php`, data);
  }

  async phoneVertify(data) {  // 인증번호 발송
    return await http.post(`/api/phoneVertify.php`, data);
  }

  // async getReadStatic(data) {  // 통계자료 생성
  //   return await http.post(`/api/getReadStatic.php`, data);
  // }

  async getReadDonePerson(data) {  // 읽은 사람들 추천
    return await http.post(`/api/getReadDonePerson.php`, data);
  }

  async recommandSetData(data) {  // 추천하기
    return await http.post(`/api/recommandSetData.php`, data);
  }

  async ifbiblereadyn(data) {  // 말씀읽었는지 체크함
    return await http.post(`/api/ifbiblereadyn.php`, data);
  }

  async drawGraphData(data) {  // 말씀온도 추출
    return await http.post(`/api/drawGraphData.php`, data);
  }

  async changePwd(data) {  // 비밀번호 변경
    return await http.post(`/api/changePwd.php`, data);
  }

  async excelDownLoad(data) {  // 회원 엑셀 다운로드
    return await http.post(`/api/excelDownLoad.php`, data);
  }

  async meditationModify(data) {  // 묵상 등록
    return http.post(`/api/bible_meditation.php`, data);
  }

  async getCommentPerson(data) {  // 묵상 등록
    return http.post(`/api/getCommentPerson.php`, data);
  }

  getGongjiAllList() {  // 공지사항 가져오기
    return http.post(`/api/getGongjiAllList.php`);
  }
  getBibleMyDiary(data) {  // 나의 묵상 조회
    return http.post(`/api/getbiblemydiary.php`, data);
  }

  getBibleRanking(data) {  // 랭킹
    return http.post(`/api/getbibleranking.php`, data);
  }

  getBibleReadClassRank(data) {  // 반별 현황
    return http.post(`/api/getbiblereadclassrank.php`, data);
  }

  getClassGraphData(data) {  // 반별 현황
    return http.post(`/api/getclassgrapthdata.php`, data);
  }
  async excelDownLoadRiseUp(data) {  // 회원 엑셀 다운로드
    return await http.post(`/api/excelDownLoadriseup.php`, data);
  }

  async voicerecoding(data) {  // 회원 엑셀 다운로드
    return await http.post(`/api/voicerecoding.php`, data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  }

  async getvoicerecoding(data) {  // 회원 엑셀 다운로드
    return await http.post(`/api/getvoicerecoding.php`, data);
  }




}

export default new ManageService();