<template>
    <div class="image-container">
      <img src="../assets/bg1.png" alt="image" class="img-fluid">
    </div>
  </template>
  
  <script>
  export default {
    name: 'ImageCentered',
  };
  </script>
  
  <style scoped>
  .image-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background-color:#2c1802; /* 이미지 아래 여백을 채우기 위한 배경색 */
  }
  
  .img-fluid {
  width: 100%;
  height: 100%;
  object-fit: cover; /* 이미지가 슬라이드를 꽉 채울 수 있도록 함 */
  top: 0;
  left: 0;

  /* 모바일에서 이미지 높이를 자동으로 조정 */
  @media (max-width: 768px) {
    height: auto;
  }
}
  </style>
  