import { createStore } from 'vuex';

const store = createStore({
  state() {
    return {
      user: null, // 사용자 정보를 저장하는 상태 변수
      calendar: {
        dialog: false
        // ... 다른 상태들 ...
      },
    };
  },
  mutations: {
    SET_USER(state, user) {
      state.user = user;
      // 사용자 정보를 로컬 스토리지에 저장
      localStorage.setItem('user', JSON.stringify(user));
    },
    CLEAR_USER(state) {
      state.user = null;
      // 로컬 스토리지에서 사용자 정보 삭제
      localStorage.removeItem('user');
    },
    RESTORE_USER(state) {
      // 로컬 스토리지에서 사용자 정보 복원
      const user = localStorage.getItem('user');
      if (user) {
        state.user = JSON.parse(user);
      }
    },
  },
  actions: {
    login({ commit }, { id, phoneNumber, password, kname, superuser }) {
      // 여기에 실제 로그인 요청 및 로그인 처리 로직을 추가합니다.
      // 서버 요청을 통해 로그인을 시도하고, 성공하면 사용자 정보를 받아옵니다.
      // 이 예제에서는 사용자 정보를 모의 데이터로 대체합니다.
      const mockUser = {
        id: id,
        name: kname, // 사용자 이름 또는 기타 정보
        phoneNumber: phoneNumber,
        password: password,
        superuser: superuser,
      };
      // 로그인 성공 시 사용자 정보를 저장합니다.
      //console.log("mockUser", mockUser);
      commit('SET_USER', mockUser);
    },
    logout({ commit }) {
      // 로그아웃 시 사용자 정보를 지웁니다.
      commit('CLEAR_USER');
    },
  },
  getters: {
    isAuthenticated: (state) => !!state.user, // 사용자가 로그인한 경우 true를 반환
  },
});

// 페이지 로드 시 사용자 정보를 복원합니다.
store.commit('RESTORE_USER');

export default store;
