<template>
  <div style="padding-top: 30px">
    <div class="container-fluid">
      <!-- 첫 번째 컨텐츠 영역 -->
      <div class="row" style="height: 30px">
        <div class="col"></div>
      </div>

      <!-- 로고 영역 -->
      <div class="row" style="height: 200px">
        <div class="col">
          <div class="d-flex justify-content-center">
            <img src="../assets/logo.png" class="img-responsive" />
          </div>
        </div>
      </div>

      <!-- 첫 번째 컨텐츠 영역 -->
      <div
        class="row"
        style="height: 50px; margin-bottom: 10px; color: firebrick"
      >
        <div class="col">
          <div v-if="gongji" class="left-scroll-text">
            {{ gongji }}
          </div>
        </div>
      </div>

      <!-- 로그인 영역 -->
      <div class="row" style="height: 200px">
        <div class="col">
          <div
            class="d-flex justify-content-center align-items-center container mt-5"
          >
            <div v-if="!isAuthenticated" class="mb-4">
              <form @submit.prevent="login">
                <div class="mb-3">
                  <input
                    type="text"
                    class="form-control"
                    id="phone"
                    v-model="phoneNumber"
                    placeholder="휴대폰 번호"
                  />
                </div>
                <div class="mb-3">
                  <input
                    type="password"
                    class="form-control"
                    id="password"
                    v-model="password"
                    placeholder="비밀번호"
                  />
                </div>
                <p class="text-lowercase">
                  <span @click="openModal">가입</span> |
                  <span @click="openModal2">비밀번호찾기</span>
                </p>
                <button type="submit" class="btn btn-primary">로그인</button>
              </form>
            </div>
            <div v-else>
              <p class="mb-4">Welcome, {{ user.name }}!</p>
              <button @click="logout" class="btn btn-danger">로그아웃</button>
              <p></p>
            </div>
          </div>
        </div>
        <p>버전 : {{ version }}</p>
      </div>

      <!-- 네 번째 컨텐츠 영역 -->
      <div class="row" style="height: 25vh">
        <div class="col">
          <!-- //내용       -->
          <div v-if="isAuthenticated" class="mb-4">
            <p class="text-lowercase">
              <button @click="openModal3" class="btn btn-light">
                비밀번호 변경
              </button>
              <button @click="openKakaoLink" class="btn btn-light">
                문의하기
              </button>
            </p>
            <p>
              <button @click="goGuide" class="btn btn-light">
                이용 가이드
              </button>
            </p>
            <p>
              <button
                @click="goDownloadExcel"
                class="btn btn-light"
                v-show="superuser === '1'"
              >
                엑셀다운로드
              </button>
              <!-- <button @click="goDownloadExcelClassEvent" class="btn btn-light">
                RiseUp_엑셀다운로드
              </button> -->
            </p>
          </div>
        </div>
      </div>
    </div>

    <!-- 모달 창 -->
    <div class="modal" ref="myModal" tabindex="-1" role="dialog">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header bg-primary text-white">
            <h5 class="modal-title">가입</h5>
            <button
              type="button"
              class="btn-close"
              data-dismiss="modal"
              aria-label="Close"
              @click="closeModal"
            ></button>
          </div>
          <div class="modal-body">
            <form @submit.prevent="submitForm">
              <div class="form-group mb-4">
                <input
                  type="text"
                  class="form-control"
                  id="phone3"
                  v-model="phoneNumber"
                  required
                  placeholder="휴대폰 번호 → 01012345678"
                />
              </div>
              <div class="form-group mb-4">
                <input
                  type="text"
                  class="form-control"
                  id="kname"
                  v-model="kname"
                  required
                  placeholder="이름"
                />
              </div>
              <div class="form-group mb-4">
                <input
                  type="password"
                  class="form-control"
                  id="password1"
                  v-model="password1"
                  required
                  placeholder="비밀번호"
                />
              </div>
              <div class="form-group mb-4">
                <input
                  type="password"
                  class="form-control"
                  id="password2"
                  v-model="password2"
                  required
                  placeholder="비밀번호 확인"
                />
              </div>
              <button type="submit" class="btn btn-primary">등록</button>
            </form>
          </div>
        </div>
      </div>
    </div>

    <!-- 비밀번호 찾기 -->
    <div class="modal" ref="modal2" tabindex="-1" role="dialog">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header bg-primary text-white">
            <h5 class="modal-title">비밀번호 변경</h5>
            <button
              type="button"
              class="btn-close"
              data-dismiss="modal"
              aria-label="Close"
              @click="closeModal2"
            ></button>
          </div>
          <div class="modal-body">
            <form @submit.prevent="submitForm2">
              <div class="form-group mb-4">
                <div class="input-group">
                  <input
                    type="text"
                    class="form-control"
                    id="phone2"
                    v-model="phoneNumber2"
                    required
                    placeholder="휴대폰 번호"
                  />
                  <div class="input-group-append">
                    <button
                      @click="sendVerificationCode"
                      class="btn btn-secondary"
                    >
                      발송
                    </button>
                  </div>
                </div>
              </div>
              <div class="form-group mb-4">
                <input
                  type="text"
                  class="form-control"
                  id="verificationCode"
                  v-model="verificationCode"
                  required
                  placeholder="인증번호"
                />
              </div>
              <div class="form-group mb-4">
                <input
                  type="password"
                  class="form-control"
                  id="password11"
                  v-model="password1"
                  required
                  placeholder="비밀번호"
                />
              </div>
              <div class="form-group mb-4">
                <input
                  type="password"
                  class="form-control"
                  id="password22"
                  v-model="password2"
                  required
                  placeholder="비밀번호 확인"
                />
              </div>
              <button type="submit" class="btn btn-primary">변경</button>
            </form>
          </div>
        </div>
      </div>
    </div>

    <!-- 비밀번호 변경 -->
    <div class="modal" ref="modal3" tabindex="-1" role="dialog">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header bg-primary text-white">
            <h5 class="modal-title">비밀번호 변경</h5>
            <button
              type="button"
              class="btn-close"
              data-dismiss="modal"
              aria-label="Close"
              @click="closeModal3"
            ></button>
          </div>
          <div class="modal-body">
            <form @submit.prevent="submitForm3">
              <div class="form-group mb-4">
                <input
                  type="password"
                  class="form-control"
                  id="password4"
                  v-model="password4"
                  required
                  placeholder="비밀번호"
                />
              </div>
              <div class="form-group mb-4">
                <input
                  type="password"
                  class="form-control"
                  id="password5"
                  v-model="password5"
                  required
                  placeholder="비밀번호 확인"
                />
              </div>
              <button type="submit" class="btn btn-primary">변경</button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import apiService from "../services/apiService";
  import * as XLSX from "xlsx";

  // import { useKakao } from 'vue3-kakao-sdk'
  // const { kakao, initialize } = useKakao()
  //import { ref } from 'vue';
  export default {
    props: {
      slideNumber: Number,
    },
    data() {
      return {
        kname: "",
        phoneNumber: "",
        password: "",
        phoneNumber2: "",
        verificationCode: "",
        password1: "",
        password2: "",
        loginData: [],
        phoneNumber3: "", //비밀번호 변경용
        password4: "", //비밀번호 변경용
        password5: "", //비밀번호 변경용
        phone:
          localStorage.getItem("user") == null
            ? ""
            : JSON.parse(localStorage.getItem("user")).phoneNumber,
        superuser:
          localStorage.getItem("user") == null
            ? ""
            : JSON.parse(localStorage.getItem("user")).superuser,
        excelData: {},
        excelData2: {},
        gongji: "",
        version: "3.2", //반별 랭킹
      };
    },
    mounted() {
      if (this.superuser === "1") {
        this.beforeExcelData();
      }

      this.beforeExcelDataRiseup();

      this.gongjiList();
    },
    methods: {
      gongjiList() {
        //한 줄 공지
        apiService
          .getGongjiAllList()
          .then((response) => {
            this.gongji = response.data.memo;
            //console.log("gongji=", response.data.memo);
            //  this.SiteLists = response.data;
          })
          .catch((e) => {
            console.log(e);
          });
      },
      login() {
        // 실제 로그인 로직을 구현하세요.
        // 사용자가 입력한 전화번호와 비밀번호를 검사하고, 유효한 경우 로그인 처리를 합니다.
        // 이 예제에서는 간단한 예제이므로 입력값만 출력합니다.
        const data = {
          phoneNumber: this.phoneNumber,
          password: this.password,
        };
        apiService
          .login(data)
          .then((response) => {
            this.loginData = response.data[0];
            //console.log("loginData = ", this.loginData.trim.length);
            if (this.loginData == null || this.loginData == "") {
              this.password = "";
              this.$toast.show("정보를 다시 확인해주세요.", {
                type: "warning",
                position: "top-right",
              });
            } else {
              this.$toast.show("로그인되었습니다.", {
                type: "success",
                position: "top-right",
              });

              // 회원가입이 성공하면 모달을 닫고 폼을 초기화합니다.
              this.$store.dispatch("login", {
                id: this.loginData.id,
                phoneNumber: this.loginData.phone,
                password: this.password,
                kname: this.loginData.name,
                superuser: this.loginData.superuser,
              });
              //window.location.reload();
              //this.swiper.slideTo(1);
            }
          })
          .catch((e) => {
            console.log(e);
          });

        //this.$store.dispatch('login');
        // this.$store.dispatch('login', { phoneNumber: this.phoneNumber, password: this.password });
      },
      logout() {
        // 로그아웃 로직을 여기에 구현
        this.password = "";
        this.$store.dispatch("logout");
        window.location.reload();
      },
      /*  가입 */
      openModal() {
        this.$refs.myModal.classList.add("show", "d-block");
      },
      closeModal() {
        this.$refs.myModal.classList.remove("show", "d-block");
        this.clearForm();
      },
      clearForm() {
        this.kname = "";
        this.phoneNumber = "";
        this.password1 = "";
        this.password2 = "";
      },
      submitForm() {
        if (this.password1 !== this.password2) {
          alert("비밀번호가 일치하지 않습니다.");
          return;
        }

        const data = {
          kname: this.kname,
          phoneNumber: this.phoneNumber,
          password: this.password1,
        };
        apiService
          .jonMember(data)
          .then((response) => {
            if (response.data == "1") {
              this.$toast.show("가입되었습니다.", {
                type: "success",
                position: "top-right",
              });

              // 회원가입이 성공하면 모달을 닫고 폼을 초기화합니다.
              this.closeModal();
            } else {
              this.$toast.show("이미 등록된 전화번호입니다.", {
                type: "warning",
                position: "top-right",
              });
            }
          })
          .catch((e) => {
            console.log(e);
          });
      },

      openModal3() {
        this.$refs.modal3.classList.add("show", "d-block");
      },
      closeModal3() {
        this.$refs.modal3.classList.remove("show", "d-block");
        this.clearForm();
      },
      clearForm3() {
        this.phoneNumber3 = "";
        this.password4 = "";
        this.password5 = "";
      },
      /*  비밀번호 찾기 */
      openModal2() {
        this.$refs.modal2.classList.add("show", "d-block");
      },
      closeModal2() {
        this.$refs.modal2.classList.remove("show", "d-block");
        this.clearForm();
      },
      clearForm2() {
        this.phoneNumber2 = "";
        this.verificationCode = "";
        this.password1 = "";
        this.password2 = "";
      },
      sendVerificationCode() {
        if (this.phoneNumber2 === null || this.phoneNumber2 === "") {
          alert("휴대폰번호를 입력하세요.");
          return;
        }

        const data = {
          phoneNumber: this.phoneNumber2,
        };
        apiService
          .phoneVertify(data)
          .then((response) => {
            if (response.data == "1") {
              this.$toast.show("인증번호가 발송되었습니다..", {
                type: "success",
                position: "top-right",
              });
              // 회원가입이 성공하면 모달을 닫고 폼을 초기화합니다.
              this.closeModal2();
            } else {
              this.$toast.show("휴대폰번호 또는 가입 여부를 확인해주세요.", {
                type: "warning",
                position: "top-right",
              });
            }
          })
          .catch((e) => {
            console.log(e);
          });
      },
      submitForm2() {
        if (this.password1 !== this.password2) {
          this.$toast.show("비밀번호가 일치하지 않습니다.", {
            type: "warning",
            position: "top-right",
          });
          return;
        }
        const data = {
          phoneNumber: this.phoneNumber2,
          verificationCode: this.verificationCode,
          password: this.password1,
        };
        apiService
          .forgetPwd(data)
          .then((response) => {
            if (response.data == "1") {
              this.$toast.show("수정되었습니다.", {
                type: "success",
                position: "top-right",
              });
              // 회원가입이 성공하면 모달을 닫고 폼을 초기화합니다.
              this.closeModal2();
            } else {
              this.$toast.show("휴대폰번호 또는 인증번호를 확인해주세요.", {
                type: "warning",
                position: "top-right",
              });
            }
          })
          .catch((e) => {
            console.log(e);
          });
      },
      submitForm3() {
        //비밀번호 변경용
        if (this.password4 !== this.password5) {
          this.$toast.show("비밀번호가 일치하지 않습니다.", {
            type: "warning",
            position: "top-right",
          });
          this.password5 = "";
          return;
        }

        if (this.phone !== this.phone) {
          this.$toast.show("새로고침 또는 재로그인을 해주세요.", {
            type: "warning",
            position: "top-right",
          });
          return;
        }

        const data = {
          phoneNumber: this.phone,
          password: this.password5,
        };

        apiService
          .changePwd(data)
          .then((response) => {
            if (response.data == "1") {
              this.$toast.show("변경되었습니다.", {
                type: "success",
                position: "top-right",
              });
              // 회원가입이 성공하면 모달을 닫고 폼을 초기화합니다.
              this.closeModal3();
            } else {
              this.$toast.show("휴대폰번호 또는 인증번호를 확인해주세요.", {
                type: "warning",
                position: "top-right",
              });
            }
          })
          .catch((e) => {
            console.log(e);
          });
      },
      openKakaoLink() {
        // 카카오톡 링크 열기
        window.open("https://open.kakao.com/o/sWIKb12d", "_blank");
      },
      goGuide() {
        // 카카오톡 링크 열기
        window.open("https://bible.dongan1718.com/guide.html", "_blank");
      },
      goDownloadExcel() {
        // 워크북 생성
        // const ws = XLSX.utils.aoa_to_sheet(this.excelData);
        const ws = XLSX.utils.json_to_sheet(this.excelData);

        // 워크북을 워크북 객체로 감싸기
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "현황");

        // 비동기로 파일 다운로드
        XLSX.writeFile(wb, "청구맥현황.xlsx");
      },
      beforeExcelData() {
        const data = {
          todate: "",
        };
        apiService
          .excelDownLoad(data)
          .then((response) => {
            this.excelData = response.data;
            //console.log("this.excelData",this.excelData);
          })
          .catch((e) => {
            console.log(e);
          });
      },
      goDownloadExcelClassEvent() {
        // 워크북 생성
        // const ws = XLSX.utils.aoa_to_sheet(this.excelData);
        const ws = XLSX.utils.json_to_sheet(this.excelData2);

        // 워크북을 워크북 객체로 감싸기
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "현황");

        // 비동기로 파일 다운로드
        XLSX.writeFile(wb, "RiseUp_청구맥읽기현황.xlsx");
      },
      beforeExcelDataRiseup() {
        const data = {
          todate: "",
        };
        apiService
          .excelDownLoadRiseUp(data)
          .then((response) => {
            this.excelData2 = response.data;
            //console.log("this.excelData",this.excelData);
          })
          .catch((e) => {
            console.log(e);
          });
      },
      //   onClickShareStory() {
      //     // Kakao Open Chat URL 열기
      //     this.$kakao.createOpenChatButton({
      //       container: '#kakao-open-chat-button', // 버튼을 표시할 DOM 요소 선택자
      //       channelPublicId: 'https://open.kakao.com/o/sWIKb12d', // 여기서 'YOUR_OPEN_CHAT_CHANNEL_ID'를 실제 오픈 채팅 채널 ID로 대체해야 합니다.
      //     });
      //  },
      //  loginWithKakao () {
      //   console.log("kakao",kakao);

      //   this.$kakao.Auth.login({
      //     success (authObj) {
      //       alert(JSON.stringify(authObj))
      //     },
      //     fail (err) {
      //       alert(JSON.stringify(err))
      //     }
      //   })
      // }
    },

    computed: {
      isAuthenticated() {
        return this.$store.getters.isAuthenticated;
      },
      user() {
        return this.$store.state.user;
      },
    },
  };
</script>
<style scoped>
  @media (max-width: 767px) {
    .img-responsive {
      max-width: 60%; /* 이미지의 최대 너비를 100%로 설정하여 모바일 화면에 맞춤 */
      height: auto; /* 이미지의 높이를 자동으로 조절하여 비율 유지 */
    }
  }
  .left-scroll-text {
    white-space: normal; /* 텍스트 줄 바꿈 허용 */
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    text-align: left;
    margin-right: 15px;
    margin-left: 15px;
  }
</style>
