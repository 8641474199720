<template>
  <div class="image-container">
    <div class="scroll-container">
      <!-- 여기에 슬라이드 또는 컨텐츠를 배치합니다. -->
      <div class="card" style="border: none">
        <div class="card-header" style="font-weight: bold">
          STEADY GREEN LIGHT
        </div>
        <div>
          <div class="row">
            <div
              class="col-2"
              style="
                font-size: 14px;
                margin-top: 12px;
                padding-left: 20px;
                padding-right: 0px;
              "
            >
              날짜:
            </div>
            <div class="col-6">
              <select
                class="form-control"
                id="todate2"
                name="todate2"
                v-model="todate2"
                @change="GetReadDonePerson()"
                style="margin-top: 5px"
              >
                <option
                  v-for="(option, index) in dateList"
                  :key="index"
                  :value="option.opendate"
                  :selected="todate2 === option.opendate"
                >
                  {{ option.opendate }}
                </option>
              </select>
            </div>
            <div
              class="col-4"
              style="
                font-size: 14px;
                margin-top: 8px;
                padding-left: 0px;
                padding-right: 0px;
              "
            >
              참여자: {{ personListCount }}명
            </div>
          </div>
        </div>
        <div style="height: 10px"></div>
        <div
          class="card-body"
          style="
            text-align: left;
            padding-top: 5px;
            padding-right: 5px;
            padding-left: 5px;
            padding-bottom: 5px;
          "
        >
          <span v-for="(item, idx) in personList" :key="idx">
            <span v-if="item.recom == 0" class="recombtn">
              <button
                type="button"
                class="btn btn-warning"
                @click="recommand(item)"
                style="margin-top: 5px"
              >
                {{ item.name }}
                <span class="badge badge-light" v-show="item.recom > 0"
                  >👍({{ item.recom }})</span
                >
              </button>
            </span>

            <span
              v-else-if="item.recom >= 1 && item.recom <= 3"
              class="recombtn"
            >
              <button
                type="button"
                class="btn btn-info"
                @click="recommand(item)"
                style="margin-top: 5px"
              >
                {{ item.name }}
                <span class="badge badge-light" v-show="item.recom > 0"
                  >👍({{ item.recom }})</span
                >
              </button>
            </span>

            <span
              v-else-if="item.recom >= 4 && item.recom <= 6"
              class="recombtn"
            >
              <button
                type="button"
                class="btn btn-success"
                @click="recommand(item)"
                style="margin-top: 5px"
              >
                {{ item.name }}
                <span class="badge badge-light" v-show="item.recom > 0"
                  >👍({{ item.recom }})</span
                >
              </button>
            </span>

            <span
              v-else-if="item.recom >= 7 && item.recom <= 9"
              class="recombtn"
            >
              <button
                type="button"
                class="btn btn-danger"
                @click="recommand(item)"
                style="margin-top: 5px"
              >
                {{ item.name }}
                <span class="badge badge-light" v-show="item.recom > 0"
                  >👍({{ item.recom }})</span
                >
              </button>
            </span>

            <span
              v-else-if="item.recom >= 10 && item.recom <= 13"
              class="recombtn"
            >
              <button
                type="button"
                class="btn btn-secondary"
                @click="recommand(item)"
                style="margin-top: 5px"
              >
                {{ item.name }}
                <span class="badge badge-light" v-show="item.recom > 0"
                  >👍({{ item.recom }})</span
                >
              </button>
            </span>

            <span v-else class="recombtn">
              <button
                type="button"
                class="btn btn-primary"
                @click="recommand(item)"
                style="margin-top: 5px"
              >
                {{ item.name }}
                <span class="badge badge-light" v-show="item.recom > 0"
                  >👍({{ item.recom }})</span
                >
              </button>
            </span>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import apiService from "../services/apiService";
  export default {
    props: {
      slideNumber: Number,
    },
    data() {
      return {
        buttonClasses: [
          "btn-primary",
          "btn-secondary",
          "btn-success",
          "btn-danger",
        ],
        todate2: this.GettoDay(),
        dateList: {},
        userid:
          localStorage.getItem("user") == null
            ? ""
            : JSON.parse(localStorage.getItem("user")).id,
        personList: {},
        feed2: 0,
        personListCount: 0,
      };
    },
    watch: {
      slideNumber(newValue) {
        if (newValue === 4) {
          //console.log(" slideNumber ?? ", newValue);
          this.userid =
            localStorage.getItem("user") == null
              ? ""
              : JSON.parse(localStorage.getItem("user")).id;
          if (this.userid == null || this.userid == "") {
            this.$toast.show("로그인이 필요합니다.", {
              type: "warning",
              position: "top-right",
            });
          }
          console.log("슬라이드 번호 4 추천 = ", newValue);
          this.GetReadDonePerson();
        }
      },
      feed2: "onRecommadChg",
    },
    methods: {
      getRandomClass() {
        const randomIndex = Math.floor(
          Math.random() * this.buttonClasses.length
        );
        return "btn " + this.buttonClasses[randomIndex] + " check";
      },
      GetDayALL() {
        const data = {
          today: this.todate2,
        };
        apiService
          .getBibleReadDay(data)
          .then((response) => {
            this.dateList = response.data;
            //  this.SiteLists = response.data;
          })
          .catch((e) => {
            console.log(e);
          });
      },
      GettoDay() {
        // 현재 날짜를 가져오기
        const today = new Date();

        // 년, 월, 일을 각각 가져오기
        const year = today.getFullYear();
        const month = String(today.getMonth() + 1).padStart(2, "0"); // 월은 0부터 시작하므로 +1 해준 후 두 자리로 포맷팅
        const day = String(today.getDate()).padStart(2, "0"); // 일자를 두 자리로 포맷팅

        // YYYYMMDD 형식으로 조합
        const formattedDate = `${year}${month}${day}`;

        //console.log("formattedDate >> ", formattedDate); // 예: ""
        return formattedDate;
      },
      GetReadDonePerson() {
        const data = {
          todate: this.todate2,
        };
        apiService
          .getReadDonePerson(data)
          .then((response) => {
            this.personList = response.data;
            this.personListCount =
              this.personList.length == null ? 0 : this.personList.length;
            //  this.SiteLists = response.data;
          })
          .catch((e) => {
            console.log(e);
          });
      },
      recommand(item) {
        //추천한다.
        // console.log("item",item);
        // console.log("this.userid",this.userid);

        if (this.userid == null || this.userid == "") {
          this.$toast.show("로그인이 필요합니다.", {
            type: "warning",
            position: "top-right",
          });
          return;
        }

        const data = {
          todate: this.todate2,
          userid: this.userid,
          targetid: item.id,
          targetno: item.no,
        };
        apiService
          .recommandSetData(data)
          .then((response) => {
            //console.log("recommandSetData",response.data);
            this.feed2 = response.data;

            if (response.data == "1") {
              this.$toast.show("말씀온도가 1도 올라갔습니다.", {
                type: "primary",
                position: "top-right",
              });
              this.GetReadDonePerson();
            } else if (response.data == "2") {
              this.$toast.show(
                "하루 한 번만 추천이 가능하고 말씀을 먼저 읽은 후 가능합니다.",
                {
                  type: "info",
                  position: "top-right",
                }
              );
            } else if (response.data == "4") {
              this.$toast.show("먼저 로그인을 해주세요.", {
                type: "warning",
                position: "top-right",
              });
            } else if (response.data == "3") {
              this.$toast.show("당일 날짜만 추천이 가능합니다.", {
                type: "info",
                position: "top-right",
              });
            } else {
              this.$toast.show(
                "처리가 안 되었습니다.관리자에게 문의해 주세요.",
                {
                  type: "danger",
                  position: "top-right",
                }
              );
            }

            //  this.personList = response.data;
            //  this.SiteLists = response.data;
          })
          .catch((e) => {
            console.log(e);
          });
      },
      onRecommadChg(newFeed, oldFeed) {
        // 변경된 값을 사용하여 원하는 동작을 수행합니다.
        // console.log('feed 변수가 변경되었습니다.');
        // console.log('새로운 값:', newFeed);
        // console.log('이전 값:', oldFeed);

        if (newFeed == "1") {
          this.$toast.show("말씀온도가 " + newFeed + "도 올라갔습니다.", {
            type: "success",
            position: "top-right",
          });
          this.feed = oldFeed;
        }

        // 예를 들어 다른 동작을 수행하거나 이벤트를 트리거할 수 있습니다.
      },
    },
    mounted() {
      this.GetDayALL();
      this.GetReadDonePerson();
    },
  };
</script>

<style scoped>
  .image-container {
    display: flow;
    width: 90%;
    /* display: flow; */
    margin-top: 20px;
    margin-left: 10px;
    margin-right: 10px;
    align-items: center;
    min-height: 100vh;
    background-color: #ffffff; /* 이미지 아래 여백을 채우기 위한 배경색 */
  }

  .scroll-container {
    max-width: 90vw; /* 반응형으로 가로 크기 설정 */
    max-height: 90vh; /*반응형으로 세로 크기 설정 */
    overflow-y: auto; /* 세로 스크롤을 활성화합니다. */
    overflow-x: hidden; /* Hide horizontal scrollbar */
  }

  .check {
    margin-left: 10px;
    margin-bottom: 5px;
  }

  .recombtn {
    margin-left: 10px;
  }
</style>
