<template>
  <div class="container my-4">
    <h2 style="font-size: 30px; margin-bottom: 30px; margin-top: 40px">
      성경일독
    </h2>
    <div class="row mb-3">
      <div class="col text-center">
        <button class="btn btn-secondary me-2" @click="previousMonth">←</button>
        <span class="h4">{{ currentMonthName }} {{ currentYear }}</span>
        <button class="btn btn-secondary ms-2" @click="nextMonth">→</button>
      </div>
    </div>

    <table class="table">
      <thead>
        <tr>
          <th scope="col" v-for="day in daysOfWeek" :key="day">{{ day }}</th>
        </tr>
      </thead>
      <tbody>
        <!-- <tr v-for="week in calendar" :key="week.toString()">
          <td v-for="day in week" :key="day.date" :class="getCellClass(day)" @click="lnker(day.date)">
            {{ day.date }}{{ formatDate(day.date) }}
          </td>
        </tr> -->
        <tr v-for="week in calendar" :key="week.toString()">
          <td
            v-for="day in week"
            :key="day.date"
            :class="getCellClass(day)"
            @click="lnker(formatDate(day.date))"
          >
            <span
              v-if="
                getEventTitle(
                  JSON.parse(highlightedDates),
                  formatDate(day.date)
                ) !== '1' && day.date
              "
              class="highlight-other"
              >{{ day.date }}
            </span>
            <span
              v-else-if="
                getEventTitle(
                  JSON.parse(highlightedDates),
                  formatDate(day.date)
                ) === '1' && day.date
              "
              class="highlight-today"
              >{{ day.date }}
            </span>
            <span v-else></span>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
  import { ref, computed, watch } from "vue";
  import apiService from "../services/apiService";
  import { useSwiper } from "swiper/vue";
  import { globalVariable, updateGlobalVariable } from "../store/store";

  export default {
    name: "BootstrapCalendar",
    props: {
      slideNumber: Number,
    },
    data() {
      return {
        //readData : {},
        sDate: "",
        eDate: "",
        events: [], // JSON 데이터
        globalVar: globalVariable,
        listDataBox: [],
      };
    },
    watch: {
      slideNumber(newValue) {
        if (newValue === 2) {
          console.log(" slideNumber ", newValue);
          this.userid =
            localStorage.getItem("user") == null
              ? ""
              : JSON.parse(localStorage.getItem("user")).id;
          if (this.userid == null || this.userid == "") {
            this.$toast.show("로그인이 필요합니다.", {
              type: "warning",
              position: "top-right",
            });
          }
          console.log("슬라이드 번호 2 = ", newValue);
          //this.initCalendarDate();

          const startDate = new Date(this.currentYear, this.currentMonth, 1);
          const endDate = new Date(this.currentYear, this.currentMonth + 1, 0);

          // 'GetReadBibleALL1' 함수 호출
          this.GetReadBibleALL1(
            this.formatDateToYYYYMMDD(startDate),
            this.formatDateToYYYYMMDD(endDate)
          );

          //this.GetReadBibleALL1();
        }
      },
    },
    methods: {
      getEventTitle(list, date) {
        // list가 배열인지 확인
        if (!Array.isArray(list)) {
          return null;
        }
        // list 내에서 date와 일치하는 이벤트 찾기
        const event = list.find((event) => event.start === date);
        // 일치하는 이벤트가 있으면 그의 title 반환, 없으면 null 반환
        return event ? event.title : null;
      },

      // lnker(day) {
      //   // lnker 함수 로직...

      //   // Swiper 인스턴스를 확인하고 slideTo 메서드를 호출합니다.
      //   // if (this.swiperInstance && this.swiperInstance.value) {
      //   //   this.swiperInstance.value.slideTo(2); // 3번 슬라이드로 이동 (인덱스는 0부터 시작하므로 2를 사용)
      //   // }
      //   //this.swiperInstance.value.slideTo(4);
      //   //console.log("mySwiperMain", this.swiperInstance.value);

      // // Swiper 인스턴스가 존재하고 slideTo 메서드를 사용할 수 있는지 확인

      //   console.log("day", day);
      // }
    },
    async created() {
      const startDate = new Date(this.currentYear, this.currentMonth, 1);
      const endDate = new Date(this.currentYear, this.currentMonth + 1, 0);

      // 'GetReadBibleALL1' 함수 호출
      await this.GetReadBibleALL1(
        this.formatDateToYYYYMMDD(startDate),
        this.formatDateToYYYYMMDD(endDate)
      );
    },
    async mounted() {
      //EventBus.$on('readBibleCompleted', this.GetReadBibleALL1);

      // 'mounted' 훅에서 시작일과 종료일 계산
      const startDate = new Date(this.currentYear, this.currentMonth, 1);
      const endDate = new Date(this.currentYear, this.currentMonth + 1, 0);

      // 'GetReadBibleALL1' 함수 호출
      await this.GetReadBibleALL1(
        this.formatDateToYYYYMMDD(startDate),
        this.formatDateToYYYYMMDD(endDate)
      );
    },

    computed: {
      highlightedDates() {
        // readData 배열에서 날짜만 추출하여 새 배열로 반환합니다.
        //return this.readData.map(item => this.formatDateToYYYYMMDD(new Date(item.date)));
        //console.log("listData=", JSON.stringify(this.listData));
        //this.events = this.listData;
        return JSON.stringify(this.listData);
      },
    },

    setup() {
      const data = { today: "20240129" };
      const date = new Date();
      const currentMonth = ref(date.getMonth());
      const currentYear = ref(date.getFullYear());

      const daysOfWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
      const swiper = useSwiper();
      const selectedDay = ref(null);

      const currentMonthName = computed(() => {
        return new Date(currentYear.value, currentMonth.value).toLocaleString(
          "default",
          { month: "long" }
        );
      });

      const calendar = computed(() => {
        const startDay = new Date(
          currentYear.value,
          currentMonth.value,
          1
        ).getDay();
        const daysInMonth = new Date(
          currentYear.value,
          currentMonth.value + 1,
          0
        ).getDate();
        let days = [];

        for (let i = 1; i <= daysInMonth; i++) {
          days.push({ date: i, isCurrentMonth: true });
        }

        const totalDays = startDay + days.length;
        const totalWeeks = Math.ceil(totalDays / 7);

        for (let i = 0; i < startDay; i++) {
          days.unshift({ date: "", isCurrentMonth: false });
        }

        while (days.length < totalWeeks * 7) {
          days.push({ date: "", isCurrentMonth: false });
        }

        return Array.from({ length: totalWeeks }, (_, i) =>
          days.slice(i * 7, i * 7 + 7)
        );
      });

      function getCellClass(day) {
        if (!day.isCurrentMonth) {
          return "not-current-month";
        }
        const formattedDate = `${currentYear.value}${String(
          currentMonth.value + 1
        ).padStart(2, "0")}${String(day.date).padStart(2, "0")}`;
        return formattedDate === data.today
          ? "highlight-today"
          : "calendar-cell";
      }

      function lnker(day) {
        // lnker 함수 로직...

        // Swiper 인스턴스를 확인하고 slideTo 메서드를 호출합니다.
        // if (this.swiperInstance && this.swiperInstance.value) {
        //   this.swiperInstance.value.slideTo(2); // 3번 슬라이드로 이동 (인덱스는 0부터 시작하므로 2를 사용)
        // }

        updateGlobalVariable(day);

        selectedDay.value = day; // 선택된 날짜를 저장

        console.log("swiper day", day);

        swiper.value.slideTo(3);
        //console.log("swiper", swiper.value);

        // Swiper 인스턴스가 존재하고 slideTo 메서드를 사용할 수 있는지 확인

        //console.log("day", day);
      }

      //provide('selectedDay', selectedDay);

      function previousMonth() {
        if (currentMonth.value === 0) {
          currentMonth.value = 11;
          currentYear.value--;
        } else {
          currentMonth.value--;
        }
        // 달력 갱신을 위한 의존성 반응 갱신
      }

      function nextMonth() {
        if (currentMonth.value === 11) {
          currentMonth.value = 0;
          currentYear.value++;
        } else {
          currentMonth.value++;
        }
        // 달력 갱신을 위한 의존성 반응 갱신
      }

      function formatDate(date) {
        if (date === "") {
          return "";
        }
        const formattedDate = `${currentYear.value}${String(
          currentMonth.value + 1
        ).padStart(2, "0")}${String(date).padStart(2, "0")}`;
        return formattedDate;
      }

      const calendarStartDate = ref(""); // 시작일
      const calendarEndDate = ref(""); // 마지막 일자
      const listData = ref(""); // data

      // currentMonth와 currentYear를 감시하여 변경 시 시작일과 마지막 일자를 업데이트합니다.
      watch([currentMonth, currentYear], () => {
        // const startDay = new Date(currentYear.value, currentMonth.value, 1).getDay();
        const daysInMonth = new Date(
          currentYear.value,
          currentMonth.value + 1,
          0
        ).getDate();

        calendarStartDate.value = new Date(
          currentYear.value,
          currentMonth.value,
          1
        ); //.toLocaleDateString();
        calendarEndDate.value = new Date(
          currentYear.value,
          currentMonth.value,
          daysInMonth
        ); // .toLocaleDateString();
        // console.log(
        //   "calendarStartDate.value",
        //   formatDateToYYYYMMDD(calendarStartDate.value)
        // );
        // console.log(
        //   "calendarEndDate.value",
        //   formatDateToYYYYMMDD(calendarEndDate.value)
        // );
        //this.sDate = formatDateToYYYYMMDD(calendarStartDate.value);
        //this.eDate = formatDateToYYYYMMDD(calendarEndDate.value);
        GetReadBibleALL1(
          formatDateToYYYYMMDD(calendarStartDate.value),
          formatDateToYYYYMMDD(calendarEndDate.value)
        );
      });

      async function GetReadBibleALL1(StartDate, EndDate) {
        const data = {
          today: "",
          userid:
            localStorage.getItem("user") == null
              ? ""
              : JSON.parse(localStorage.getItem("user")).id,
          // calendarStartDate: formatDateToYYYYMMDD(calendarStartDate.value),
          // calendarEndDate: formatDateToYYYYMMDD(calendarEndDate.value),
          calendarStartDate: StartDate,
          calendarEndDate: EndDate,
        };

        console.log("data == ", data);
        await apiService
          .getBibleReadMonth(data)
          .then((response) => {
            //console.log(">>>>GetReadBibleALL ++  response>> = ", response.data);
            //this.listDataBox = response.data;
            listData.value = response.data;
            // listData = response.data;
            // console.log(">>>>GetReadBibleALL >> = ", response.data);
          })
          .catch((e) => {
            console.log(e);
          });
      }

      function formatDateToYYYYMMDD(date) {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, "0"); // 월은 0부터 시작하므로 1을 더하고 자릿수를 맞춥니다.
        const day = String(date.getDate()).padStart(2, "0"); // 일자를 자릿수를 맞춥니다.
        return `${year}${month}${day}`;
      }

      //return { currentMonth, currentYear, daysOfWeek, calendar, currentMonthName, previousMonth, nextMonth, lnker, getCellClass, formatDate };
      return {
        currentMonth,
        currentYear,
        daysOfWeek,
        calendar,
        currentMonthName,
        previousMonth,
        nextMonth,
        getCellClass,
        formatDate,
        calendarStartDate,
        calendarEndDate,
        GetReadBibleALL1,
        formatDateToYYYYMMDD,
        listData,
        swiper,
        lnker,
      };
    },
  };
</script>

<style>
  .calendar-cell {
    background-color: #f2f2f2;
    cursor: pointer;
  }

  .calendar-cell:hover {
    background-color: #e6e6e6;
  }

  .highlight-today {
    background-color: #dba901;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    display: inline-block;
    margin: 0px;
  }

  .highlight-other {
    background-color: #a0522d;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    display: inline-block;
    margin: 0px;
    color: white;
  }

  .highlight-zero {
    background-color: #ffffff;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    display: inline-block;
    margin: 0px;
    color: white;
  }

  .not-current-month {
    background-color: #ffffff; /* 이전/다음 달의 날짜에 대한 스타일 */
  }
</style>
