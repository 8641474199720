<template>
  <keep-alive>
    <swiper
      :spaceBetween="30"
      :pagination="{
        type: 'progressbar',
        clickable: true,
      }"
      :modules="modules"
      class="mySwiper"
      :initialSlide="0"
      :onSwiper="onSwiperInit"
      ref="mySwiperMain"
      :navigation="true"
      @slideChange="onSlideChange"
    >
      <!-- 첫 페이지  -->
      <swiper-slide><FirshPage :slideNumber="slideNumber" /></swiper-slide>

      <!-- 1. 로그인 -->
      <swiper-slide><BibleMain :slideNumber="slideNumber" /></swiper-slide>

      <!-- 2 반별 랭킹 그래프 -->
      <!-- <swiper-slide
        ><BibleReadingClassRank :slideNumber="slideNumber"
      /></swiper-slide> -->

      <!-- 3. 달력 -->
      <swiper-slide :swiper-instance="mySwiperMain"
        ><ReadCalendar :slideNumber="slideNumber"
      /></swiper-slide>

      <!-- 4 말씀 -->
      <swiper-slide><BibleReadDone :slideNumber="slideNumber" /></swiper-slide>

      <!-- 5. 추천 -->
      <swiper-slide><BibileView :slideNumber="slideNumber" /></swiper-slide>

      <!-- 6. 묵상  -->
      <swiper-slide
        ><BibleMeditation :slideNumber="slideNumber"
      /></swiper-slide>

      <!-- 묵상 6 -->
      <!-- <swiper-slide><BibleRanking :slideNumber="slideNumber" /> </swiper-slide> -->

      <!-- 7. 반별 명단 -->
      <swiper-slide><BibleClassRank :slideNumber="slideNumber" /></swiper-slide>

      <!-- 8. 나의 묵상 -->
      <swiper-slide><BibleMyDiary :slideNumber="slideNumber" /></swiper-slide>

      <!-- 9. 말씀온도 -->
      <swiper-slide><ReadPeople :slideNumber="slideNumber" /></swiper-slide>
    </swiper>
  </keep-alive>
</template>
<script>
  // Import Swiper Vue.js components
  import { Swiper, SwiperSlide } from "swiper/vue";

  // Import Swiper styles
  import "swiper/css";
  import "swiper/css/pagination";
  import "swiper/css/navigation";
  import "./style.css";
  import { ref } from "vue";
  // import required modules
  import { Pagination, Navigation } from "swiper/modules";

  import FirshPage from "./components/FirshPage.vue";
  import BibleMain from "./components/BibleMain";
  import BibleReadDone from "./components/BibleReadDone";
  import ReadCalendar from "./components/ReadCalendar";
  // import LoginForm from './components/LoginForm.vue';
  import ReadPeople from "./components/ReadPeople";
  //import BibleReadStatus from './components/BibleReadStatus';
  import BibileView from "./components/BibileView";
  //import Datepicker from 'vue3-datepicker';

  import BibleMeditation from "./components/BibleMeditation";
  import BibleMyDiary from "./components/BibleMyDiary";
  //import BibleRanking from "./components/BibleRanking";
  import BibleClassRank from "./components/BibleClassRank";

  //import BibleReadingClassRank from "./components/BibleReadingClassRank";

  //import TestCalendar from './components/TestCalendar'

  export default {
    components: {
      Swiper,
      SwiperSlide,
      FirshPage,
      BibleMain,
      BibleReadDone,
      ReadCalendar,
      //   LoginForm,
      ReadPeople,
      //  BibleReadStatus,
      BibileView,
      BibleMeditation,
      //Datepicker,
      //TestCalendar,
      BibleMyDiary,
      //BibleRanking,
      BibleClassRank,
      //BibleReadingClassRank,
    },
    data() {
      return {};
    },
    methods: {},
    setup() {
      // 전역 변수 설정
      let globalslideNo = ref(0);
      const mySwiperMain = ref(null); // ref 생성
      // 슬라이드 초기화 이벤트 핸들러
      const onSwiperInit = (swiper) => {
        // 슬라이드 변경 이벤트 리스너 등록
        swiper.on("slideChange", () => {
          const activeSlideIndex = swiper.activeIndex;
          // console.log(`현재 활성 슬라이드 인덱스: ${activeSlideIndex}`);
          // 전역 변수 업데이트
          globalslideNo.value = activeSlideIndex;
          //console.log("현재 활성 슬라이드 인덱스: ", globalslideNo.value);
        });
      };

      const onSlideChange = () => {
        // 슬라이드 변경 시 상단으로 스크롤
        window.scrollTo(0, 0);

        if (navigator.onLine === false) {
          // 인터넷 연결이 불안정할 때 슬라이드 변경을 막습니다.
          mySwiperMain.value.swiper.slideTo(globalslideNo.value, 0, false);
        }
      };

      return {
        modules: [Pagination, Navigation],
        mySwiperMain, // mySwiperMain ref를 반환합니다.
        onSwiperInit,
        slideNumber: globalslideNo,
        onSlideChange,
      };
    },
  };
</script>
