import axios from "axios";

var baseAppURL = process.env.VUE_APP_API_URL;

console.log("baseURL", baseAppURL);

if (baseAppURL == "http://localhost/") {
    baseAppURL = "http://localhost:8080";
} else {
    baseAppURL = "https://bible.dongan1718.com";
}

export default axios.create({
    //baseURL: "http://localhost:8080", //개발
    //baseURL: "https://manna.dongan1718.com/", //운영
    baseURL: baseAppURL,
    headers: {
        "Content-type": "application/json",
        //"Access-Control-Allow-Origin": "http://localhost:8080",
        "Access-Control-Allow-Origin": "https://bible.dongan1718.com",
        "Content-Security-Policy":
            "default-src 'self'; script-src 'self'; style-src 'self'; font-src 'self'; img-src 'self'; frame-src 'self'",
    },
    withCredentials: true,
});
