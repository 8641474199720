import { createApp } from 'vue'
import App from './App.vue'
import 'bootstrap/dist/css/bootstrap.css'
import "bootstrap";
import store from './store'
import Toaster from "@meforma/vue-toaster";
import OneSignalVuePlugin from '@onesignal/onesignal-vue3'
import './registerServiceWorker'
import VueKakaoSdk from 'vue3-kakao-sdk'
import router from './router';


// 전역 변수를 생성하고 제공
//const globalVar = ref('globalslideNo');
const app = createApp(App);
const apiKey = '5c0d991012e295539693804c69628d3a'

app.use(store)
    .use(router)
    .use(Toaster)
    .use(VueKakaoSdk, { apiKey })
    .use(OneSignalVuePlugin, { appId: 'dd1cbdbc-3465-463e-910c-6c0f7e2b4185', })
    .mount('#app')

